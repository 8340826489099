@import "../_variables.scss";

$default: $color-dark;
$default_dark: $color-black;

$success: $color-success-d;
$success_dark: $color-success-dd;

$danger: $color-danger-d;
$danger_dark: $color-danger-dd;

$info: $color-info-d;
$info_dark: $color-info-dd;

$warning: $color-warning-d;
$warning_dark: $color-warning-dd;

$awesome: #685dc3;
$awesome_dark: #4c3fb1;

$default_timer: $color-gray;
$default_timer_filler: $color-white;

$success_timer: $color-success;
$success_timer_filler: $color-success-ll;

$danger_timer: $color-danger;
$danger_timer_filler: $color-danger-ll;

$info_timer: $color-info;
$info_timer_filler: $color-info-ll;

$warning_timer: $color-warning;
$warning_timer_filler: $color-warning-ll;

$awesome_timer: #685dc3;
$awesome_timer_filler: #fff;