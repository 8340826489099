@import "./_variables.scss";

// ====================================================================================================================================================================================================
//  LOGIN
// ====================================================================================================================================================================================================

#Login {

    // En la pantalla de login cambiamos el color de fondo
    position: absolute;
    background: $color-primary;
    width: 100%;
    height: 100%;

    // Separación vertical de los botones
    button.btn { margin-top: 10px; }

    // Caja del formulario
    .login-formulario {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: auto;
        margin-top: 80px;
        border: 1px solid #ced4da;
        border-radius: $radio-redondo;
        background: $color-body;
        box-shadow: $sombra-componente;
    }

}
