@import "./_variables.scss";

/* ================================================================================================================================================================================================= *\
    PREMIOS
\* ================================================================================================================================================================================================= */

/*	General */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */


#Niveles {


    .nivel {
        background: $color-danger;
        padding: 5px;
        border-radius: 5px;
        color: $color-danger-dd;
    }


}