@import "./_variables.scss";

/* ================================================================================================================================================================================================= *\
    CONFIGURACIÓN
\* ================================================================================================================================================================================================= */

#Configuracion {

	padding-top: 50px;
	.form-control:focus { border: 1px solid #ced4da; }


    .foto {
        display: inline-block;
        width: 300px; height: 300px; 
        border: 1px solid #9BA9BB;
        border-radius: 50%;
        background-position: center center;
        background-size: cover;
        vertical-align: bottom;
    }
    
}
