@import "./_variables.scss";

/* ================================================================================================================================================================================================= *\
    PREMIOS
\* ================================================================================================================================================================================================= */

/*	General */
/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */


#Premios {

	.ctCoins {
        color: $color-warning;
        //animation: color-coin 2s infinite;
        padding-top: 1px;
		span {
            text-align: center;
			color: $color-warning-dd;
			position: absolute;
			margin-top: 13px;
			width: 48px;
		}
	}
	//.ctCoins:hover { color: $color-warning-l; }

}


#Premios .premio {

    border-top: 1px solid $color-gray;
    margin-bottom: 40px;

    h4 { padding-top: 25px; text-transform: none; font-size: 15px; }
    img { width: 100%; max-width: 200px; }

}

/*
@keyframes color-coin {
    0% { color: $color-warning-l; }
    50% { color: $color-warning; }
    100% { color: $color-warning-l; }
}
*/