@import "./_variables.scss";

// ====================================================================================================================================================================================================
//  PEGATINAS
// ====================================================================================================================================================================================================

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  TABLÓN
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#Pegatinas .Tablon {
	.icono {
		width: 30px; height: 30px;
		margin-right: 15px;
        margin-bottom: 15px;
        cursor: pointer;
    }
    // Separamos los botones selectores
    .btn { margin-left: 10px; }
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  EVOLUCIÓN
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#Pegatinas .Evolucion {
    // Separamos el botón de actualizar
    .btn { margin-left: 10px; }
}
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  ÚLTIMAS
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#Pegatinas .Ultimas {

    .lista { list-style: none; padding-left: 0; }
    .lista li { padding-bottom: 20px; }

    // Separamos los botones selectores
    .btn { margin-left: 10px; }

	.datos { margin-left: 60px; i { position: relative; top: -8px;} }
	
	.texto { margin-left: 60px; font-size: 13px; }

    .fecha { font-size: 13px; color: $color-gray; float: right; }
    
    .pegatina {

		display: inline-block;
		position: absolute;
		width: 60px; height: 60px;
		padding-left: 10px;
        padding-top: 5px;
        
        img {
            width: 30px; height: 30px;
            margin-right: 15px;
        }
    
        small {
    
            position: absolute;
            margin-left: -25px; margin-top: 15px;
            padding-top: 4px;
            width: 22px ; height: 22px;
            text-align: center;
            font-size: 10px;
            border-radius: 50%;
    
        }
        small.positivo { background: $color-success; color: $color-success-dd; }
        small.negativo { background: $color-danger; color: $color-danger-dd; }
    
    
    }




}



// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  RANKING
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#Pegatinas .Ranking {

    // Separamos los botones selectores
    .btn { margin-left: 10px; margin-top: 5px; }

    .ganador {

        padding: 15px 0;
        border-bottom: 1px solid $color-primary;
        margin-bottom: 15px;
        text-align: center;
    
        .foto {
            display: inline-block;
            width: 80px; height: 80px;
            border: 1px solid $color-primary; 
            border-radius: 50%;
            background-position: center center;
            background-size: cover;
            vertical-align: bottom;
        }
    
        small { position: absolute; background: $color-warning; color: $color-warning-dd; padding: 5px; border-radius: 15px; margin-left: -20px; }
        strong { position: relative; left: 0px;}

    }

    .siguientes {
        list-style: none;
        
        li { margin-bottom: 15px; margin-left: -45px; text-align: center; }
        small { background: $color-warning; color: $color-warning-dd; padding: 5px; border-radius: 15px; float: left; width: 35px; }
    }

}
