@import "./_variables.scss";

// ====================================================================================================================================================================================================
//  NOTICIAS
// ====================================================================================================================================================================================================

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  Lista Noticias
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#ListaPerfiles {

	.perfil {
		cursor: pointer;
		border-radius: 25px;
	}

	.perfil:hover { background: #DDD; }

	.usuario {
		cursor: pointer;
		border-radius: 25px;
	}

	.usuario:hover { background: #DDD; }

	.imagen {
		width: 100%; height: 150px;
		border: 1px solid #9C9C9C;
		border-radius: 5px;
		background-position: center center;
		background-size: cover;
	}

	.nombre {
		display: block;
		font-weight: bolder;
		font-size: 1rem;
		padding-bottom: 10px;
	}

	.descripcion {
		display: block;
		padding-bottom: 5px;
	}

	@media (min-width: 576px) and (max-width: 991.98px) {
		.imagen { height: 250px; }
	}

}



// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  Perfil Individual
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


#PerfilEquipo {

	.imagen {
		margin: auto;
		width: 100%;
		height: 300px;
		border: 1px solid #9C9C9C;
		border-radius: 15px;
		background-position: center center;
		background-size: cover;
		margin-bottom: 15px;
	}

}

#PerfilUsuario {

	.background {
		width: 100%;
		height: 260px;
		border-radius: 0;
		background-position: center center;
		background-size: cover;
		padding: 30px;
		margin-bottom: 20px;
	}

	.imagen {
		width: 200px;
		height: 200px;
		border: 1px solid #9C9C9C;
		border-radius: 15px;
		background-position: center center;
		background-size: cover;
		margin-bottom: 15px;
	}

}



/*
#noticias-ultimas li { margin-left: 40px; margin-bottom: 15px; }
#noticias-ultimas h5 small { color: #666; }
#noticias-ultimas img { border-radius: 25px; margin-right: 15px; }


// En los móviles más pequeños tenemos que centrar las cosas una debajo de otra
@media (max-width: 500px) {
	#noticias-ultimas li { display: block; text-align: center; margin-top: 30px; margin-bottom: 30px;}
	#noticias-ultimas li img { margin-bottom: 5px; }
}

*/