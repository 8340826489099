

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// 	Colores Genéricos
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

$color-primary:    #9BA9BB;
$color-secondary:  #BA9BBB;

$color-success:    #A0C2BA;
$color-danger:     #F27461;
$color-warning:    #F3C766;
$color-info:       #9BA9BB;

$color-black:      #333333;
$color-dark:       #666666;
$color-gray:       #AAAAAA;
$color-light:      #E6E6E6;
$color-white:      #FFFFFF;

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// 	Configuración
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// Sombra que se aplica a los componentes
$sombra-componente: 3px 3px 15px -5px $color-dark;

// Radio de curvatura de los componentes
$radio-redondo: 10px;
// Radio de curvatura de las cosas que vayan dentro de los componentes, el radio de redondeo será un poco más pequeño
$radio-redondito: 5px;

// Separación entre los componentes horizontal y verticalmente
$separacion-componentes: 20px;





// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// 	Colores Concretos
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// Color fondo de la aplicación
$color-body:       $color-light;

// Color fondo de componentes y menú
$color-fondo:      $color-white;



// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// 	Alteraciones de Color
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// Lighten & Darken
$color-primary-ll: lighten($color-primary,15%);
$color-primary-l: lighten($color-primary,10%);
$color-primary-d: darken($color-primary,20%);
$color-primary-dd: darken($color-primary,30%);
$color-secondary-ll: lighten($color-secondary,15%);
$color-secondary-l: lighten($color-secondary,10%);
$color-secondary-d: darken($color-secondary,20%);
$color-secondary-dd: darken($color-secondary,30%);
$color-success-ll: lighten($color-success,15%);
$color-success-l: lighten($color-success,10%);
$color-success-d: darken($color-success,20%);
$color-success-dd: darken($color-success,30%);
$color-danger-ll: lighten($color-danger,15%);
$color-danger-l: lighten($color-danger,10%);
$color-danger-d: darken($color-danger,20%);
$color-danger-dd: darken($color-danger,30%);
$color-warning-ll: lighten($color-warning,15%);
$color-warning-l: lighten($color-warning,10%);
$color-warning-d: darken($color-warning,20%);
$color-warning-dd: darken($color-warning,30%);
$color-info-ll: lighten($color-info,15%);
$color-info-l: lighten($color-info,10%);
$color-info-d: darken($color-info,20%);
$color-info-dd: darken($color-info,30%);

