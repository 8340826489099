@import "./_variables.scss";

// ====================================================================================================================================================================================================
//  NOTICIAS
// ====================================================================================================================================================================================================

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  Lista Noticias
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#ListaNoticias {

	.noticia {
		cursor: pointer;
		border-radius: 25px;
	}

	.noticia:hover { background: #DDD; }

	.imagen {
		width: 100%; height: 150px;
		border: 1px solid #9C9C9C;
		border-radius: 5px;
		background-position: center center;
		background-size: cover;
	}

	@media (min-width: 576px) and (max-width: 991.98px) {
		.imagen { height: 250px; }
	}

}



// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  Noticia Individual
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


#Noticia {


	.izquierda {

		padding: 10px;

		.imagen {
			margin: auto;
			width: 100%;
			height: 300px;
			border: 1px solid #9C9C9C;
			border-radius: 15px;
			background-position: center center;
			background-size: cover;
		}
	
	}

	.derecha {
		padding: 20px;

		small {
			display: block;
			text-align: right;
			color: $color-gray;
		}
	}

	.contenido {
		padding: 50px;
		p {
			color: $color-black;
			font-size: 16px;
			line-height: 30px;
			strong { font-weight: bold; }
		}

	}

}
/*
#noticias-ultimas li { margin-left: 40px; margin-bottom: 15px; }
#noticias-ultimas h5 small { color: #666; }
#noticias-ultimas img { border-radius: 25px; margin-right: 15px; }


// En los móviles más pequeños tenemos que centrar las cosas una debajo de otra
@media (max-width: 500px) {
	#noticias-ultimas li { display: block; text-align: center; margin-top: 30px; margin-bottom: 30px;}
	#noticias-ultimas li img { margin-bottom: 5px; }
}

*/