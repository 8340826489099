@import "./_variables.scss";

// ====================================================================================================================================================================================================
//  FORMULARIO DE PEGATINAS
// ====================================================================================================================================================================================================


// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  General
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#FormPegatinas {

    display: block;
    background: rgba(0,0,0,0.5);

	.modal-header { height: 60px; display: block; background: $color-primary-ll; }
	.cerrar { float: right; position: relative; top: -10px; right: -15px; }
	.form-control:focus { border: 1px solid #ced4da; }

	.listaPegatinas { height: 130px; }
	.pegatinas { position: absolute; }
	.pegatinas.visibles { visibility: visible; }
    .pegatinas.ocultas { visibility: hidden; }
    
    .saldo {
        float: right;
        font-size: 15px;
		line-height: 35px;
        color: $color-primary-dd;
    }

}


// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  Usuarios
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


#FormPegatinas .listaUsuarios {

	.usuarios {
		width: 100%;
		height: 145px;
		white-space: nowrap;
		overflow-x: auto;
	}

	.usuario {

		opacity: 0.4;
		filter: grayscale(1);
		display: inline-block;
		margin: 10px;
		border-radius: 15px;
		transition: opacity 0.2s linear, filter 0.2s linear;

		.foto {
			display: block;
			width: 80px; height: 80px; 
			border: 1px solid #9BA9BB;
			border-radius: 50%;
			background-position: center center;
			background-size: cover;
		}

		small {
			width: 100%;
			display: inline-block;
			text-align: center;
		}

	}

	.usuario.seleccionado { opacity: 1; filter: grayscale(0); }
	//.usuario.ui-droppable-active {}

	.usuario.ui-droppable-hover { opacity: 1; filter: grayscale(0); }

    // Separamos los botones selectores
    .btn { margin-left: 10px; }

}



// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  Pegatinas
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#FormPegatinas .listaPegatinas {

	padding-top: 15px;

	.pegatinas {
		text-align: center;
    	width: 100%;
	}

	.pegatina {
		cursor: pointer;
		width: 48px; height: 48px;
		padding: 5px;
		margin: 5px;
		border: 1px solid #9BA9BB;
		border-radius: 15px;
		background: #fff;
		box-shadow: $sombra-componente;
	}

	.pegatina.ui-draggable-dragging {
		z-index: 10;
		transform: rotate(7deg);
	}
    
    // Separamos los botones selectores y ponemos tamaño fijo
    .btn { margin: 0; margin-left: 5px; width: 40px; }
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  Mensaje
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#FormPegatinas .mensaje {

    padding-top: 15px;

}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  Estrellas
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


.efectoObjeto {
	position: fixed;
	z-index: 9999;
}
