@import "./_variables.scss";

#Kanban {

	margin-left: 50px;
    white-space: nowrap;
	overflow-y: auto;
	overflow-x: scroll;

	.lista {

		width: 200px;
		padding: 10px;
		display: inline-block;
		vertical-align: top;
		margin-right: 50px;
		margin-bottom: 50px;
		background: #fff;
		border-radius: 25px;
		box-shadow: 3px 3px 15px -5px #555;

		.cabecera {
			width: 100%;
			font-size: 15px;
			margin-bottom: 10px;
			font-weight: bold;
			padding: 0 10px;
		}
	
		.pie {
			font-size: 15px;
			margin-top: 10px;
			color: #777;
			padding: 0 10px;
		}

		ul {
			width: 100%;
			min-height: 20px;
			list-style-type: none;
			margin: 0;
			padding: 5px 0 0 0;
			margin-right: 10px;
			cursor: pointer;
		}

		li {
			margin: 0 5px 5px 5px;
			padding: 10px;
			font-size: 13px;
			width: 170px;
			background: #9BA9BB;
			color: #ffffff;
			border-radius: 5px;
			box-shadow: 0 1px 0 rgba(9,30,66,.25);
		}
		
		li:hover {
			background: #eef;
			color: #666;
		}

	}
	
	
	

	.ui-sortable-helper {
		transform: rotate(7deg);
	}
	
	.placeholder-carta {
		background: #ccd !important;
		height: 18px;
	}
	
	.placeholder-lista {
		display: inline-block;
		height: 18px;
		width: 250px;
	}
	

}