@import "./_variables.scss";

// ====================================================================================================================================================================================================
//  MENÚ SUPERIOR
// ====================================================================================================================================================================================================

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  MENÚ SUPERIOR
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#MenuSuperior {
	
	// Posición: Arriba del todo fijo, independiente del scroll del contenido y alineado a la derecha
	position: fixed;
	z-index: 97;
	right: 30px; top: 30px;

}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  BOTONES
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#MenuSuperior ul {

	/* Posición: Lo hacemos flotar a la derecha */
	float: right;
	
	/* Tamaño: Le ponemos la altura */
    height: 55px;

	/* Espacio: Separación entre botones y el contenedor */
    padding: 10px;
	
	/* Decoración: Color de fondo blanco, borde redondo y algo de sombra */
	background: $color-white;	
    border-radius: 25px;
    box-shadow: $sombra-componente;
	
	li {

		/* Decoración: Los elementos de la lista los ponemos uno al lado de otro */
		display: inline-block;
	
	}

	button { color: $color-dark; }
	
}


// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  MEDIA QUERIES
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


@media (min-width: 768px) {
	#MenuSuperior { display:none; }
	//#MenuSuperior ul li.solo-xs-sm { display:none; }
}

@media (max-width: 767.98px) {
	#MenuSuperior ul li.solo-md-lg-xl { display:none; }
	//#Root.menu-abierto #MenuSuperior ul li.solo-md-lg-xl { display:block; }
}