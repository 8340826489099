@import "./estilos/_variables.scss";
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

// ====================================================================================================================================================================================================
//  VARIABLES BOOTSTRAP
// ====================================================================================================================================================================================================

//$body-bg: $color-fondo;
$grid-gutter-width: $separacion-componentes;

// ====================================================================================================================================================================================================
//  IMPORTAMOS BOOTSTRAP
// ====================================================================================================================================================================================================

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
//@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/media";
//@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
//@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
//@import "~bootstrap/scss/print";


// ====================================================================================================================================================================================================
//  Modificamos Bootstrap
// ====================================================================================================================================================================================================

// Esto hace que los formularios modales estén siempre visibles sin necesidad de usar Javascript. Así podemos controlarlos con React
.modal {
    display: block;
    background: rgba(0,0,0,0.5);
    overflow: auto;
}


// ====================================================================================================================================================================================================
//  CSS GLOBAL
// ====================================================================================================================================================================================================

// El tipo de letra usada en la aplicación y el color de fondo
body {
    font-family: 'Roboto', sans-serif;
    background: $color-body;
}

// Hacemos que root coja todo el ancho (parece que no es necesario)
//#root { width: 100%; }

// Títulos en la web
h1 { position: absolute; display: block; color: $color-gray; font-size: 10px;}
h2 { display: block; color: $color-dark;}
h3 { display: block; color: $color-dark; margin-bottom: 15px; text-transform: uppercase; font-weight: bolder; font-size: 1rem; }
h4 { display: block; color: $color-dark; margin-bottom: 15px; text-transform: uppercase; font-weight: bolder; font-size: 0.8rem; }

// Textos y enlaces
p {
    font-family: 'Roboto', sans-serif;
    font-size: 0.9em;
    font-weight: 300;
    line-height: 1.5em;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

// No queremos el efecto de sombra azul de Bootstrap al seleccionar un elemento .form-control
.form-control:focus {
    border: 1px solid #ced4da;
    box-shadow: none;
}

// Colocamos las notificaciones en la posición 0,0
.react-notification-root { left: 0; top: 0; }

// Añadimos una velocidad más a animate.css
.animated.fastest { -webkit-animation-duration: 200ms; animation-duration: 200ms; }



// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  FUNCIONAMIENTO DEL MENÚ LATERAL
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

	/* XS */ @media (max-width: 575.98px) {
		#MenuLateral { left: -250px; }
		#App {width: 100%; }
	}

	/* SM */ @media (min-width: 576px) and (max-width: 767.98px) {
		.container { width: 556px; }
		#MenuLateral { left: -250px; }
		#App { width: 100%;}
	}

	/* MD */ @media (min-width: 768px) and (max-width: 991.98px) {
		.container { width: 498px; }
	}

	/* LG */ @media (min-width: 992px) and (max-width: 1199.98px) {
		.container { width: 722px; }
	}

	/* XL */ @media (min-width: 1200px) {
		.container { width: 930px; }
	}

	// Si el #Root tiene la clase "menu-abierto" colocamos el menú lateral en la posición cero y mostramos la capa de fondo
	@media (max-width: 767.98px) {
		#root.menu-abierto #MenuLateral { left: 0; }
		#root.menu-abierto #MenuCapa { opacity: 1; visibility: visible; }
	}


// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  PERSONALIZADO
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

	// Elimina el borde azul de cuando el botón está en focus
	.nofocus.focus, .nofocus:focus{
		box-shadow: none !important;
	}

	// Botón con el fondo blanco
	.btn-blanco {
		color: $color-dark;
		background: none;
		border: none;
		border-radius: $radio-redondo;
	}

	// Colores Texto
	.txtAzul     { color: $color-info;}
	.txtRojo     { color: $color-danger;}
	.txtVerde    { color: $color-success;}
	.txtAmarillo { color: $color-warning;}
	.txtNegro    { color: $color-black;}
	.txtGris     { color: $color-gray;}




	// Pone fondo blanco con borde redondeado y un poco de sombra
	.componente {
		padding: 30px;
		background: $color-fondo;
		border-radius: $radio-redondo;
		margin-bottom: $separacion-componentes;
		box-shadow: $sombra-componente;
		color: $color-dark;

		.cerrar {
			float: right;
			position: relative;
			top: -30px;
			right: -30px;
		}

	}



	.ct-usuario-h {

		position: relative;
		display: inline-block;
		background: $color-primary-l;
		color: #fff;
		border-radius: 15px;
		padding: 0 10px 0 0;
		margin: 0 5px 0 5px;
		text-align: left;
		width: 110px; height: 30px;
		overflow: hidden;
	
		.foto {
			display: inline-block;
			width: 30px; height: 30px;
			border: 1px solid $color-primary-l;
			border-radius: 50% 0 0 50%;
			background-position: center center;
			background-size: cover;
			vertical-align: bottom;
			margin-right: 3px;
		}

		span {
			position: absolute;
			font-size: 13px;
			margin-top: 5px;
			margin-left: 3px;
		}
	
	}
	





	/* Posición: absoluta / relativa / izquierda / derecha / z-index / ... */
	/* Tamaño: ancho / alto / ... */
	/* Espacio: padding / margin / ...  */	
	/* Fuente: tamaño / color / negrita / ... */	
	/* Decoración: bordes / colorFondo / ... */	
	/* Transiciones: transition / animaciones / transformaciones / ... */


	/* XS - Extra small devices (portrait phones, less than 576px) */
	/* @media (max-width: 575.98px) { ... } */

	/* SM - Small devices (landscape phones, 576px and up) */
	/* @media (min-width: 576px) and (max-width: 767.98px) { ... } */

	/* MD - Medium devices (tablets, 768px and up) */
	/* @media (min-width: 768px) and (max-width: 991.98px) { ... } */

	/* LG - Large devices (desktops, 992px and up) */
	/* @media (min-width: 992px) and (max-width: 1199.98px) { ... } */

	/* XL - Extra large devices (large desktops, 1200px and up) */
    /* @media (min-width: 1200px) { ... } */
    