@import "./_variables.scss";


// ====================================================================================================================================================================================================
//  MENÚ LATERAL
// ====================================================================================================================================================================================================

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  GENERAL
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#MenuLateral {

	// Posición: Por defecto lo ponemos fixed en la posición arriba-izquierda
	position: fixed;
	z-index: 99;
	left: 0; top: 0;

	// Tamaño: El menú ocupará 250px en total y llegará hasta abajo del todo
    width: 250px; height: 100%;

	// Decoración: Color de fondo igual que los componentes, sombra y posibilidad de ver barra de scroll vertical
	background: $color-fondo;
	box-shadow: $sombra-componente;
	overflow: hidden auto;

	// Transiciones: Para que el movimiento al cerrarse sea suave
    transition: left 0.3s;

}




// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  PERFIL
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


#MenuLateral .perfil {
	
	padding: 15px 15px 0px 15px;
	text-align: center;

	.foto {
		border: 1px solid $color-primary;
		border-radius: 50%;
		width: 180px; height: 180px;
		background-position: center center;
		background-size: cover;
		margin: 15px;
	}

	// Al poner el ratón encima de la foto, colocamos una capa transparente con el icono de configuración
	.foto::after {

		content: "\f013";
		color: $color-dark;
		font-weight: 900;
		font-family: "Font Awesome 5 Free";
		font-size: 50px;
		display: block;
		width: 100%; height: 100%;
		padding-top: 50px;
		border-radius: 50%;
		background: $color-light;

		opacity: 0;
		transition: opacity 0.4s ease-out;
	}

	.foto:hover::after { opacity: 0.9; }

	.ctCoins {
		position: absolute;
		margin-left: 160px;
		color: $color-warning;
		span {
			color: $color-warning-dd;
			position: absolute;
			margin-top: 13px;
			width: 48px;
		}
	}
	.ctCoins:hover { color: $color-warning-l; }

}


// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  NIVEL
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#MenuLateral .nivel {

	padding: 10px;
    margin-top: -17px;
	height: 60px;
	color: $color-danger;
	
	.izquierda {
		
		float: left;
		width: 40px;

		.valor {
			color: $color-danger-dd;
			position: absolute;
			margin-left: 13px;
			margin-top: 6px;
		}
	}

	.derecha {
		
		float: left;
		width: 190px;

		.nombre {
			color: $color-danger-dd;
			margin-left: 5px;
		}	

		.progress {
			width: 190px;
			margin-top: -3px;
			height: 10px;
			background-color: $color-light;
			.progress-bar { background-color: $color-danger; }
		}

	}

}


// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  BOTONES
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#MenuLateral .botones {
	border-top: 1px solid $color-light;
	text-align: center;
	border-bottom: 1px solid $color-light;

	.btn { color: $color-primary; }
	.btn:hover { color: $color-primary-dd; }
	.btn > span { position: relative; left: 5px; top: -5px; }
}


// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  OPCIONES
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


#MenuLateral ul.opciones {
    padding: 20px 0px 20px 40px;
    border-bottom: 1px solid $color-light;
}

// Enlaces de las opciones que no están activas
#MenuLateral ul li {

	a {
		padding: 10px;
		font-size: 14px;
		display: block;
		color: $color-dark;
		border-radius: 10px 0 0 10px;
	}

	a:hover {
		color: $color-primary;
	}
}

// Enlaces de la opción que está activa
#MenuLateral ul li > a.active {
    color: $color-white;
	background: $color-primary;
}


// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  EXTRAS
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


#MenuLateral .extras {

	text-align: center;

	a, button { font-size: 20px; color: $color-gray; }
	a:hover, button:hover { font-size: 20px; color: $color-dark; }

}


// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  CAPA OSCURA QUE CUBRE EL CONTENIDO AL ABRIR EL MENÚ
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#MenuCapa {
	
	// Posición: Fixed porque queremos que sea independiente del scroll del contenido y que esté por encima del contenido pero debajo del menú lateral
	position: fixed;
	z-index: 98;
	left: 0; top: 0;

	// Tamaño: Que ocupe toda la pantalla
	width: 100%; height: 100%;

	// Decoración: Lo ponemos negro transparente pero lo ocultamos hasta que se indique lo contrario
	background: rgba(0,0,0,0.5);
	opacity: 0; visibility: hidden;

	// Transiciones: Para mostrar/ocultar de forma suave
	transition: opacity 0.4s, visibility 0.4s;
	
}

@media (max-width: 767.98px) {
	#Root.menu-abierto #MenuCapa { opacity: 1; visibility: visible; }
}


/*
	TODO ESTO SIRVE CUANDO EL MENÚ TIENE SUBMENÚS

#MenuLateral a[aria-expanded="true"] {
    color: #fff;
	background: #9BA9BB;
    border-radius: 10px 0 0 10px;
}

#MenuLateral a[data-toggle="collapse"] {
    position: relative;
}

#MenuLateral .dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}


#MenuLateral ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #FFFFFF;
	color: #6d6e70;
}
*/
