@import "./_variables.scss";

// ====================================================================================================================================================================================================
//  INICIO
// ====================================================================================================================================================================================================

#Inicio {
	
	// Reseteamos el padding horizontal del componente contador y cumpleaños, para poder centrar. Y le ponemos sólo 15px por arriba. También le ponemos una altura fija
    .componente.contador, .componente.cumple { padding: 15px 0; height: 160px; }

	// Contador
	.contador {
    
		// Centramos todo el contenido
		text-align: center;
		
		// Icono
		.icono {  font-size: 26px; }

		// Texto Título
		.texto h3 { margin-bottom: 3px; }

		// Texto Subtítulo
		.texto small { color: $color-gray; font-style: italic; }

		// Número
		.texto div {  font-size: 24px; }

	}

    // Cumpleaños
    .cumple {

        .icono { padding-bottom: 10px; }
    
        h3 { text-align: center; margin-bottom: 0px; }
        h3 i { padding-right: 15px; }
    
        ul { list-style: none ; text-align: center; padding-left: 0; margin-bottom: 0; }
    
        li {
            display: inline-block;
            width: 70px;
            text-align: center;
            border-radius: 30px;
    
            .foto {
                display: inline-block;
                width: 50px; height: 50px;
                border: 1px solid #9BA9BB;
                border-radius: 50%;
                background-position: center center;
                background-size: cover;
                vertical-align: bottom;
                margin-right: 3px;
            }
            span { display: block; font-size: 12px; color: #666; }
            small { position: relative; font-size: 10px; top: -9px; color: #999; }
    
        }
    
        li.hoy { background: #F3C766; }
    }


}



// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  INFORMACIONES
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  ÚLTIMAS NOTICIAS
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#UltimasNoticias {

    .noticia {
        display: block;
        height: 180px;
        padding: 15px;
        border-radius: 15px;
        margin-bottom: 15px;
    }

    .noticia:hover { background: $color-light; }

	.foto {
		float: left;
		width: 200px; height: 150px;
		border: 1px solid $color-dark;
		border-radius: 5px;
		background-position: center center;
		background-size: cover;
		margin-right: 15px;
		@media (max-width: 991.98px) { float: none; width: 100%; margin-bottom: 15px; }
	}
	

	.componente { min-height: 250px; }
}