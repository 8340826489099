@import "./_variables.scss";

// ====================================================================================================================================================================================================
//  APP
// ====================================================================================================================================================================================================

#App {
	
	// Posición: La ponemos absolute en la posición arriba-derecha
	position: absolute;
	top: 0; right: 0;

	// Tamaño: El contenido ocupa todo menos el tamaño del menú que es 250px
	width: calc(100% - 250px);
	min-height: 100vh;

	// Espacio: Dejamos un poco de hueco arriba
	padding: 20px 0 0 0;

}

