@import "./_variables.scss";

#Loading {
	background: #555;
    height: 100%;
    width: 100%;
    position: fixed;
    padding-top: 200px;
    color: #AAA;
}
