@import "./_variables.scss";

// ====================================================================================================================================================================================================
//  CHAT
// ====================================================================================================================================================================================================

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  Lista Chats
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#ListaChats {

    ul {
        list-style: none;
        padding-left: 0;
    }

	.chat {
        cursor: pointer;
        padding: 5px;
        position: relative;

        .foto {
            width: 50px; height: 50px;
            border: 1px solid #9C9C9C;
            border-radius: 5px;
            background-position: center center;
            background-size: cover;
            display: inline-block;
        }

        strong {
            position: absolute;
            top: 10px;
            left: 70px;
        }

        small {
            position: absolute;
            right: 5px;
            top: 14px;
        }

        i {
            position: absolute;
            left: 70px;
            bottom: 10px;
            font-size: 13px;
        }
    }



    .chat:hover { background: $color-light; }

/*
	.imagen {
		width: 100%; height: 150px;
		border: 1px solid #9C9C9C;
		border-radius: 5px;
		background-position: center center;
		background-size: cover;
	}

	@media (min-width: 576px) and (max-width: 991.98px) {
		.imagen { height: 250px; }
	}
*/
}



// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  Lista Mensajes
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------


#Chats .Mensajes {
        
    ul {
        list-style: none;
        padding-left: 0;
    }


    .mensaje.text-left { background: $color-success-ll; color: $color-success-dd; }
    .mensaje.text-right { background: $color-primary-ll; color: $color-primary-dd; }
    
    
    .mensaje {
        
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 5px;
        
        strong { color: $color-dark; }
        p { margin-bottom: 0; }
        small { font-size: 70%; }
        

    }



    .final { height: 200px; }

}


// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  Caja para escribir
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

#Chats .escribir {

    padding: 15px 20px;
    position: fixed;
    bottom: 0;

    textarea { width: 100%; border: none; resize: none; outline: none; }
    textarea.focus { box-shadow: none; }
    .textarea:focus { box-shadow: none; }
    textarea::placeholder { transition: opacity 0.3s; }
    textarea:focus::placeholder { opacity: 0; }

    @media (min-width: 1200px) { width: 910px; }
    @media (min-width: 992px) and (max-width: 1199.98px) { width: 702px; }
    @media (min-width: 768px) and (max-width: 991.98px) { width: 478px; }
    @media (min-width: 576px) and (max-width: 767.98px) { width: 520px; }
    @media (max-width: 575.98px) { width: 100%; left: 0; }

}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
//  Popup Nuevo Chat
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

.PopupChats {
    
    .usuarios {
        height: 300px;
        overflow-y: scroll;        
    }

    .usuario {
        height: 50px;
        padding: 5px;    
    }

    .usuario:hover { cursor: pointer; background: $color-light;}
    
    .usuario.seleccionado { background: $color-primary; }
    
    span {
        line-height: 35px;
        padding-left: 15px;
    }

    .foto {
        display: inline-block;
        width: 40px; height: 40px;
        border: 1px solid $color-primary; 
        border-radius: 50%;
        background-position: center center;
        background-size: cover; 
        vertical-align: bottom;
    }

}